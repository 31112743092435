import React from 'react'
import { graphql } from 'gatsby'
import Layout from 'layouts/zh'
import {
  Seo,
  Banner,
  SummaryTitle,
  TabSwitcher,
  Button,
  ImagePreview,
  ScanCodeConsultButton,
  PageBottomCard,
  TestimonyCard,
  MoreResources,
} from 'components/index'
import Section from 'components/SolutionComponents/Section'
import { ButtonGroup } from 'components/Base/Button'
import GreenCard from 'components/Analysis/GreenCard'
import NewShowCard from 'components/engines/NewShowCard'
import SigningPlate from 'components/Solutions/SigningPlate'
import Carousel from 'components/Carousel'
import classnames from 'classnames'
import useIsWindowWidthSmaller from 'hooks/useWindowWidth'

import * as styles from './index.module.less'
import CjaEnginesPreview from './img/CjaEnginesPreview.png'

const Cja = (props) => {
  const { data } = props
  const { cjaYaml } = data
  const { banner, part2, part3, part4, part5, part6, part7 } = cjaYaml

  const isMb = useIsWindowWidthSmaller()

  return (
    <Layout {...props}>
      <Seo
        title="神策客户旅程分析引擎 | 用户行为分析平台"
        description="神策客户旅程分析引擎能够提升企业可视化数据分析能力，帮助企业深入了解客户需求并基于用户反馈优化产品和服务体验，从而提升客户满意度和营销效果，用数据驱动业绩快速增长。"
        keywords="客户旅程分析,用户需求分析,用户行为分析,客户分析,指标管理,数据可视化"
      />

      <div className={styles.CJARoot}>
        <div className={styles.bannerWrapper}>
          <Banner
            title={banner?.title}
            desc={banner?.desc}
            buttons={banner?.buttons}
            enTitle={banner?.enTitle}
            className={classnames(styles.banner)}
            h1tag={true}
            bannerTitleClassName={styles.bannerTitle}
            bannerDescClassName={styles.bannerDesc}
            bannerBtnsClass={styles.bannerBtnsClass}
            enTitleClassName={styles.enTitle}
          />
        </div>
        {/* 以客户旅程分析为基础，深入了解你的客户 */}
        <div className={styles.wWarrper}>
          <div className="whitespace-pre">
            <SummaryTitle
              title={isMb ? `以客户旅程分析为基础\n深入了解你的客户` : '以客户旅程分析为基础，深入了解你的客户'}
              desc="从链接到扩展，赋能企业挖掘潜在的经营机会"
            />
          </div>
          <div className="lg:flex lg:justify-center md:mt-[73px] mt-[2.6rem] md:px-0 px-[2rem]">
            {part2?.stepArr?.map((item) => {
              return <NewShowCard className="lg:mx-[13px] mb-[10px] lg:mb-0" key={item?.title} data={item} />
            })}
          </div>
          <div className="flex justify-center mt-[30px] lg:mt-[60px]">
            <Button btnType="primary" href={part2?.button?.href} target="_blank">
              {part2?.button?.text}
            </Button>
          </div>
        </div>
        <div className={styles.bWarrper}>
          <SummaryTitle title="客户旅程分析引擎的核心能力" desc="满足全链路分析需求，为企业创造持续的竞争优势" />
          <div className="flex justify-center md:mt-[60px] mt-[3rem] px-[2rem]">
            <div className="lg:w-[1047px]">
              <ImagePreview imageUrl={CjaEnginesPreview} alt="客户旅程优化引擎" />
            </div>
          </div>
          <ButtonGroup className={classnames('md:mt-[60px] mt-[3rem] flex justify-center')}>
            <Button href="https://www.sensorsdata.cn/form/parade.html" target="_blank" btnType="primary">
              预约演示
            </Button>
            <ScanCodeConsultButton
              className="lg:mx-[20px] ml-[16px] bg-[#fff] hover:!bg-[#E6FAF5]"
              qrcode="https://ow-file.sensorsdata.cn/www/solutions/overview/wxcode.png"
              size="small"
              target="_blank"
              buttonText="立即咨询"
              imgBottomText="微信扫码 立即咨询"
              ghost
              buttonType="primary"
            />
          </ButtonGroup>
        </div>
        <div className={styles.wWarrper}>
          <SummaryTitle title="分析，远不止是数据统计" desc="从旅程视角洞见转化关键，重塑客户体验、激发业绩潜力" />
          <div className={styles.partContent}>
            <TabSwitcher
              data={part3.data}
              align="flex-start"
              mbShowShadow={false}
              autoWAndH
              longTitle
              itemContenWrapperClass={styles.itemContenWrapperClass}
              tabsClass={isMb ? null : styles.tabsClass}
              tabItemClass={isMb ? null : styles.tabItemClass}
            />
          </div>
        </div>
        <div className={styles.bWarrper}>
          <SummaryTitle title="为什么选择神策？" desc="四大核心优势，助力企业实现体验优化和业务增长" />
          <div className="lg:mt-[60px] mt-[26px] lg:w-[1200px] mx-auto px-[2rem] lg:px-0 lg:flex lg:justify-between">
            {part4?.dataSource?.map((item) => {
              return <GreenCard className="mt-[14px] lg:mt-0" key={item?.title} dataSource={item} />
            })}
          </div>
          <ButtonGroup className="lg:mt-[60px] mt-[30px] text-center">
            <Button href="https://www.sensorsdata.cn/form/parade.html" target="_blank" btnType="primary">
              预约演示
            </Button>
            <ScanCodeConsultButton
              className="ml-[20px]"
              qrcode="https://ow-file.sensorsdata.cn/www/product/analysis/wechatQRcode.png"
              buttonText="立即咨询"
              imgBottomText="微信扫码 立即咨询"
              btnType="primary"
              ghost
            />
          </ButtonGroup>
        </div>
        {/* 灵活下钻数据，赋能企业经营分析能力 */}
        <section className={styles.section}>
          <div className="flex justify-center md:mt-[0px] pl-[20px] pr-[20px]">
            <TestimonyCard {...part6} />
          </div>
          <div
            className={classnames(
              'md:h-[100px] h-[50px] lg:w-[1070px] lg:px-0 mx-[auto] px-[20px] w-full',
              styles.carouselWrapper,
            )}
          >
            <Carousel
              img={part6?.icons?.publicURL || part6?.icons}
              className={styles.carousel}
              showWhiteMask
              iconContain
              itemClassName00={styles.item00}
              itemClassName01={styles.item01}
            />
          </div>
        </section>
        {/* 更多干货 */}
        <Section className="lg:!mt-0 lg:pt-[60px] lg:pb-[70px] !pt-[5rem] pb-[0] bg-[#f9fafc]" title={part7?.title}>
          <div className="flex justify-center md:mt-[60px] mt-[2rem] pl-[20px] pr-[20px]">
            <MoreResources data={part7?.dataSource} />
          </div>
        </Section>
        <div className={styles.wWarrper} style={{ paddingBottom: '0px' }}>
          <SummaryTitle title="其他平台引擎" />
          <SigningPlate
            className="lg:block md:bg-white"
            dataSource={part5}
            linkIconClassName={styles.linkIconClassName}
            enTitle={part5?.enTitle}
          />
          <div className="lg:mt-[40px] mt-[2.6rem]">
            <PageBottomCard
              title="开启大数据分析与营销科技之旅！"
              desc="立即注册，和神策数据专家一起探讨数字化！"
              leftButtonText="体验 Demo"
              leftButtonHref="/demo/demo.html"
              rightButtonText="预约演示"
              rightButtonHref="/form/parade.html"
              type="newBg"
            />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    cjaYaml {
      banner {
        title
        enTitle
        desc
        buttons {
          text
          href
          btnType
          ghost
        }
      }
      part2 {
        stepArr {
          title
          desc
          img {
            publicURL
          }
          mbImg {
            publicURL
          }
        }
        button {
          text
          href
        }
      }
      part3 {
        data {
          title
          reverse
          showIcon
          sortTitle
          content {
            img {
              publicURL
            }
            alt
            introduction1
            buttons {
              btnType
              href
              text
              target
            }
            introductionList {
              content
            }
          }
        }
      }
      part4 {
        dataSource {
          title
          desc
          content
        }
      }
      part5 {
        left {
          title
          bg {
            publicURL
          }
          desc
          btn_text
          btn_link
        }
        right {
          title
          bg {
            publicURL
          }
          desc
          btn_text
          btn_link
        }
        enTitle
      }
      part6 {
        title
        text
        signature
        imgUrl {
          publicURL
        }
        icons {
          publicURL
        }
      }
      part7 {
        title
        dataSource {
          img {
            publicURL
          }
          title
          tip
          url
          text
        }
      }
    }
  }
`

export default Cja
